<section class="row makePaymentView c-list-orders" id="productList-area">
  <!-- <aside class="d-none d-md-block col-md-4 col-lg-3 applied-filter pr-0">
    <div class="c-list-orders--aside">
      <div class="line-intersection"></div>
      <section>
        <div class="banner-img">
          <app-flu-dashboard-banner
            bannerComponent="OrderHistoryBannerComponent"
          ></app-flu-dashboard-banner>
        </div>
      </section>
    </div>
  </aside> -->

  <section class="col-md-12">
    <table class="c-table c-table--branded d-none d-lg-table">
      <thead>
        <tr>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'docNo', paymentList, 'normal')"
          >
            {{ 'makeAPayment.invoice#' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'blineDate', paymentList, 'Date')"
          >
            {{ 'makeAPayment.invoice_date' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'dueDate', paymentList, 'Date')"
          >
            {{ 'makeAPayment.due_date' | cxTranslate }}
            <i class="my-icon fas fa-caret-up"></i>
          </th>
          <th
            scope="col"
            class="col-width"
            (click)="sortColumn($event, 'amount', paymentList, 'Number')"
          >
            {{ 'makeAPayment.invoice_balance' | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of paymentList; let i = index">
          <td class="pay">
            <a
              *ngIf="
                data.docType === 'RV' ||
                data.docType === 'RD' ||
                data?.docType === 'RF'
              "
              href="javascript: void(0);"
              class="underline"
              (click)="invoicePdf(data.refDocNo)"
            >
              {{ data.refDocNo }}
            </a>

            <a
              *ngIf="data.docType === 'DZ' && data.allocNmbr.startsWith('7')"
              href="javascript: void(0);"
              class="underline"
              (click)="invoicePdf(data.allocNmbr)"
            >
              {{ data.allocNmbr }}
            </a>
            <span
              *ngIf="
                data.docType === 'DR' ||
                data.docType === 'DG' ||
                data?.docType === 'DY'
              "
            >
              {{ data.refDocNo }}
            </span>
            <span
              *ngIf="data.docType === 'DZ' && !data.allocNmbr.startsWith('7')"
            >
              {{ data.allocNmbr }}
            </span>
            <span *ngIf="data.docType === 'YR'">
              {{ data.refhdr }}
            </span>
          </td>
          <td class="pay">{{ data.blineDate | euStringToUSDate }}</td>
          <td class="pay">
            <span
              [ngClass]="
                !!data.dueDate && dateComparison(data.dueDate) ? 'red' : ''
              "
              >{{ data.dueDate | euStringToUSDate }}
              <ng-template #popSaveContent>
                <span class="popover-content">{{
                  'makeAPayment.past_due_date' | cxTranslate
                }}</span>
              </ng-template>
              <span
                [ngbPopover]="popSaveContent"
                triggers="manual"
                #p1="ngbPopover"
                placement="top"
                (click)="toggle(p1)"
                container="body"
                popoverClass="popover-links-invoice"
                *ngIf="!!data.dueDate && dateComparison(data.dueDate)"
              >
                <img
                  src="assets/icons/warning_red.png"
                  alt="warningIcon"
                  class="warningIconEvent"
                />
              </span>
            </span>
          </td>
          <td class="pay">{{ data.amount | currency }}</td>
        </tr>
      </tbody>
    </table>

    <table
      aria-describedby="invoiceTable"
      class="c-table c-table--branded c-table--branded--two-col d-table d-lg-none"
    >
      <tr *ngFor="let data of paymentList; let i = index">
        <td class="header">
          {{ 'makeAPayment.invoice#' | cxTranslate }}
        </td>
        <td>
          <a
            *ngIf="
              data.docType === 'RV' ||
              data.docType === 'RD' ||
              data?.docType === 'RF'
            "
            href="javascript: void(0);"
            class="underline"
            (click)="invoicePdf(data.refDocNo)"
          >
            {{ data.refDocNo }}
          </a>

          <a
            *ngIf="data.docType === 'DZ' && data.allocNmbr.startsWith('7')"
            href="javascript: void(0);"
            class="underline"
            (click)="invoicePdf(data.allocNmbr)"
          >
            {{ data.allocNmbr }}
          </a>
          <span
            *ngIf="
              data.docType === 'DR' ||
              data.docType === 'DG' ||
              data?.docType === 'DY'
            "
          >
            {{ data.refDocNo }}
          </span>
          <span
            *ngIf="data.docType === 'DZ' && !data.allocNmbr.startsWith('7')"
          >
            {{ data.allocNmbr }}
          </span>
          <span *ngIf="data.docType === 'YR'">
            {{ data.refhdr }}
          </span>
        </td>
        <td class="header">
          {{ 'makeAPayment.invoice_date' | cxTranslate }}
        </td>
        <td>{{ data.blineDate | euStringToUSDate }}</td>
        <td class="header">
          {{ 'makeAPayment.due_date' | cxTranslate }}
        </td>
        <td>{{ data.dueDate | euStringToUSDate }}</td>
        <td class="header">
          {{ 'makeAPayment.invoice_balance' | cxTranslate }}
        </td>
        <td>{{ data.amount | currency }}</td>
      </tr>
    </table>
    <div class="col-md-12 mt-4 mt-lg-0">
      <p
        class="text-medium-fixed text-center"
        *ngIf="!(isRetrievingData | async) && !paymentList.length"
      >
        {{ 'ordersList.noOrders' | cxTranslate }}
      </p>
      <p class="text-medium-fixed text-center" *ngIf="isRetrievingData | async">
        {{ 'ordersList.loadingOrders' | cxTranslate }}
      </p>
    </div>
  </section>
</section>
<!--
<section class="row makePaymentView" id="productList-area">
  <section class="col-md-10 col-sm-9 table-s-section float-right">
    <section
      *ngIf="openCreditInvoice?.length === 0 || creditList?.length === 0"
    >
      <h3 class="no-order-tittle">
        {{ "makeAPayment.credits_title" | cxTranslate }}
      </h3>
      <section class="no-credit-invoice">
        {{ "makeAPayment.no_open_credits" | cxTranslate }}
      </section>
    </section>
    <section
      *ngIf="openCreditInvoice?.length === 0 || paymentList?.length === 0"
    >
      <h3 class="no-order-tittle">
        {{ "makeAPayment.invoice_title" | cxTranslate }}
      </h3>
      <section class="no-credit-invoice">
        {{ "makeAPayment.no_open_invoices" | cxTranslate }}
      </section>
    </section>
    <section *ngIf="paymentList.length > 0" class="payment-section">
      <section class="row">
        <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
          <h3 class="oi-table-title">
            {{ "makeAPayment.invoice_title" | cxTranslate }}
          </h3>
        </section>
      </section>

      <table
        aria-describedby="invoicesTable"
        id="applyCreditsTable"
        class="payments-table table table-striped"
      >
        <thead>
          <tr>
            <th
              scope="col"
              class="col-width"
              (click)="sortColumn($event, 'docNo', paymentList, 'normal')"
            >
              {{ "makeAPayment.invoice#" | cxTranslate }}
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th
              scope="col"
              class="col-width"
              (click)="sortColumn($event, 'blineDate', paymentList, 'Date')"
            >
              {{ "makeAPayment.invoice_date" | cxTranslate }}
              <i class="my-icon fas fa-caret-down"></i>
            </th>
            <th
              scope="col"
              class="col-width"
              (click)="sortColumn($event, 'dueDate', paymentList, 'Date')"
            >
              {{ "makeAPayment.due_date" | cxTranslate }}
              <i class="my-icon fas fa-caret-up"></i>
            </th>
            <th
              scope="col"
              class="col-width"
              (click)="sortColumn($event, 'amount', paymentList, 'Number')"
            >
              {{ "makeAPayment.invoice_balance" | cxTranslate }}
              <i class="my-icon fas fa-caret-down"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of paymentList; let i = index">
            <td class="pay">
              <a
                *ngIf="
                  data.docType === 'RV' ||
                  data.docType === 'RD' ||
                  data?.docType === 'RF'
                "
                href="javascript: void(0);"
                class="underline"
                (click)="invoicePdf(data.refDocNo)"
              >
                {{ data.refDocNo }}
              </a>

              <a
                *ngIf="data.docType === 'DZ' && data.allocNmbr.startsWith('7')"
                href="javascript: void(0);"
                class="underline"
                (click)="invoicePdf(data.allocNmbr)"
              >
                {{ data.allocNmbr }}
              </a>
              <span
                *ngIf="
                  data.docType === 'DR' ||
                  data.docType === 'DG' ||
                  data?.docType === 'DY'
                "
              >
                {{ data.refDocNo }}
              </span>
              <span
                *ngIf="data.docType === 'DZ' && !data.allocNmbr.startsWith('7')"
              >
                {{ data.allocNmbr }}
              </span>
              <span *ngIf="data.docType === 'YR'">
                {{ data.refhdr }}
              </span>
            </td>
            <td class="pay">{{ data.blineDate }}</td>
            <td class="pay">
              <span
                [ngClass]="
                  !!data.dueDate && dateComparison(data.dueDate) ? 'red' : ''
                "
                >{{ data.dueDate }}
                <ng-template #popSaveContent>
                  <span class="popover-content">{{
                    "makeAPayment.past_due_date" | cxTranslate
                  }}</span>
                </ng-template>
                <span
                  [ngbPopover]="popSaveContent"
                  triggers="manual"
                  #p1="ngbPopover"
                  placement="top"
                  (click)="toggle(p1)"
                  container="body"
                  popoverClass="popover-links-invoice"
                  *ngIf="!!data.dueDate && dateComparison(data.dueDate)"
                >
                  <img
                    src="assets/icons/warning_red.png"
                    alt="warningIcon"
                    class="warningIconEvent"
                  />
                </span>
              </span>
            </td>
            <td class="pay">{{ data.amount | currency }}</td>
          </tr>
        </tbody>
      </table>

      <section class="invoices-table-2column">
        <div
          class="row invoices-row-table"
          *ngFor="let data of paymentList; let i = index"
        >
          <div class="column invoices-col-headerrwd">
            <section class="">
              <p
                scope="col"
                (click)="sortColumn($event, 'docNo', paymentList, 'normal')"
              >
                {{ "makeAPayment.invoice#" | cxTranslate }}
                <i class="my-icon fas fa-caret-down"></i>
              </p>
              <p
                scope="col"
                (click)="sortColumn($event, 'blineDate', paymentList, 'Date')"
              >
                {{ "makeAPayment.invoice_date" | cxTranslate }}
                <i class="my-icon fas fa-caret-down"></i>
              </p>
              <p
                scope="col"
                (click)="sortColumn($event, 'dueDate', paymentList, 'Date')"
              >
                {{ "makeAPayment.due_date" | cxTranslate }}
                <i class="my-icon fas fa-caret-up"></i>
              </p>
              <p
                scope="col"
                class=""
                (click)="sortColumn($event, 'amount', paymentList, 'Number')"
              >
                {{ "makeAPayment.invoice_balance" | cxTranslate }}
                <i class="my-icon fas fa-caret-down"></i>
              </p>
            </section>
          </div>
          <div class="column desc-column">
            <section>
              <p class="pay">
                <a
                  *ngIf="
                    data.docType === 'RV' ||
                    data.docType === 'RD' ||
                    data?.docType === 'RF'
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="invoicePdf(data.refDocNo)"
                >
                  {{ data.refDocNo }}
                </a>

                <a
                  *ngIf="
                    data.docType === 'DZ' && data.allocNmbr.startsWith('7')
                  "
                  href="javascript: void(0);"
                  class="underline"
                  (click)="invoicePdf(data.allocNmbr)"
                >
                  {{ data.allocNmbr }}
                </a>
                <span
                  *ngIf="
                    data.docType === 'DR' ||
                    data.docType === 'DG' ||
                    data?.docType === 'DY'
                  "
                >
                  {{ data.refDocNo }}
                </span>
                <span
                  *ngIf="
                    data.docType === 'DZ' && !data.allocNmbr.startsWith('7')
                  "
                >
                  {{ data.allocNmbr }}
                </span>
                <span *ngIf="data.docType === 'YR'">
                  {{ data.refhdr }}
                </span>
              </p>
              <p class="pay">{{ data.blineDate }}</p>
              <p class="pay">
                <span class="red"
                  >{{ data.dueDate }}
                  <ng-template #popSaveContent>
                    <span class="popover-content">{{
                      "makeAPayment.past_due_date" | cxTranslate
                    }}</span>
                  </ng-template>
                  <span
                    [ngbPopover]="popSaveContent"
                    triggers="manual"
                    #p1="ngbPopover"
                    placement="top"
                    (click)="toggle(p1)"
                    container="body"
                    popoverClass="popover-links-invoice"
                    *ngIf="!!data.dueDate && dateComparison(data.dueDate)"
                  >
                    <img
                      src="assets/icons/warning_red.png"
                      alt="warningIcon"
                      class="warningIconEvent"
                    />
                  </span>
                </span>
              </p>
              <p class="pay">{{ data.amount | currency }}</p>
            </section>
          </div>
        </div>
      </section>

      <button
        [routerLink]="['/', 'openInvoicePayment']"
        [ngClass]="invoiceItems.length != 0 ? '' : 'display'"
        type="button"
        class="btn btn-primary float-right btnpaymentinvoicecredit"
        (click)="initiateInvoicePaymentFlow()"
      >
        {{ "makeAPayment.continue_button_txt" | cxTranslate }}
      </button>
    </section>
  </section>
</section> -->
