<section class="checkout-modal modal-selector">
  <section class="modal-dialog">
    <section class="modal-content">
      <div class="close">
            <i class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
              (click)="modal.dismissAll()"></i>
      </div>
        <section class="modal-header cart-Modal-Header">
            <h2 class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
              id="modal-basic-title">
              {{ 'header.selectAccountForCart' | cxTranslate }}
          </h2>
        </section>
        <section class="modal-body">
          <section class="input-group loc-SearchBar">
            <div class="c-text-input">
                  <cx-icon class="cx-icon fas fa-search"></cx-icon>
                  <input type="text" #clientName (keyup)="
                  SearchClientName(clientName.value)
                  " class="form-control searchboxnew" placeholder="{{
                  'account.filterByLocation' | cxTranslate
                  }}	" />
            </div>
          </section>
          <section>
            <div [ngClass]="{
            displayNone:
              searchedClientList?.length === 0
            }" class="custom-radio radio_grp label-location w-100 mt-4">
              <table aria-describedby="shiptolocation" *ngFor="
              let data of searchedClientList;
              let i = index
              " class="w-100 mt-2">
                <tr class="line-location" [ngClass]="{
                'label-location':
                  i !== searchedClientList.length - 1
                }">
              <td class="u-border-top-grey pt-1">
                    <input type="radio" (change)="onOptionsSelected(data)" [id]="data?.id"
                  [checked]="
                      selectedData?.uid == data?.unitID
                    " name="customRadio" class="custom-control-input" />
                <label
                  class="custom-control-label secloct w-100 text-fixed-medium font-bold"
                      [for]="data?.id">
                  {{ data?.companyName }}
                  {{ '(' + data?.unitID + ')' }}
                </label>
                    <p class="labelpp mb-2 text-fixed-medium">
                  {{
                    data?.line1
                      ? data?.line1 + ', '
                      : ''
                      }}
                      {{
                      data?.line2
                      ? data?.line2 + ', '
                      : ''
                      }}
                      {{
                      data?.town
                      ? data?.town + ', '
                      : ''
                      }}
                      {{
                      data?.region?.isocodeShort
                      ? data?.region?.isocodeShort +
                      ' '
                      : ''
                      }}
                      {{ data?.postalCode }}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </section>
      <section class="modal-footer justify-content-start">
        <button type="button" class="c-button c-button--primary ml-3" (click)="onSubmit()">
          {{ 'account.done' | cxTranslate }}
        </button>
      </section>
    </section>
  </section>
</section>